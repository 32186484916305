import { NgModule } from "@angular/core";
import { S25ChangePasswordComponent } from "./s25.change.password.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [S25ChangePasswordComponent],
    imports: [S25LoadingInlineModule, CommonModule, FormsModule],
    providers: [S25ChangePasswordComponent],
    exports: [S25ChangePasswordComponent],
})
export class S25ChangePasswordModule {
    constructor() {}
}
