import { NgModule } from "@angular/core";
import { S25CancelRequestButtonComponent } from "./s25.cancel.request.button.component";
import { CommonModule } from "@angular/common";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "../modal/modal.module";

@NgModule({
    declarations: [S25CancelRequestButtonComponent],
    imports: [CommonModule, S25LoadingInlineModule, FormsModule, ModalModule],
    providers: [S25CancelRequestButtonComponent],
    exports: [S25CancelRequestButtonComponent],
})
export class S25CancelRequestModule {
    constructor() {}
}
