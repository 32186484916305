import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from "@angular/core";

@Directive({
    selector: "[s25-ng-capture-event]",
})
export class S25CaptureEvent implements AfterViewInit, OnDestroy {
    @Output() "capture.click" = new EventEmitter<PointerEvent>();
    @Output() "capture.keydown" = new EventEmitter<KeyboardEvent>();
    @Output() "capture.keyup" = new EventEmitter<KeyboardEvent>();
    @Output() "capture.keypress" = new EventEmitter<KeyboardEvent>();
    @Output() "capture.mousedown" = new EventEmitter<MouseEvent>();
    @Output() "capture.mouseup" = new EventEmitter<MouseEvent>();
    @Output() "capture.mouseover" = new EventEmitter<MouseEvent>();
    @Output() "capture.mouseenter" = new EventEmitter<MouseEvent>();
    @Output() "capture.mouseleave" = new EventEmitter<MouseEvent>();
    @Output() "capture.mouseout" = new EventEmitter<MouseEvent>();
    @Output() "capture.scroll" = new EventEmitter<MouseEvent>();
    @Output() "capture.contextmenu" = new EventEmitter<MouseEvent>();
    @Output() "capture.focus" = new EventEmitter<FocusEvent>();
    @Output() "capture.blur" = new EventEmitter<FocusEvent>();

    listeners = {
        click: (event: PointerEvent) => this["capture.click"].emit(event),
        keydown: (event: KeyboardEvent) => this["capture.keydown"].emit(event),
        keyup: (event: KeyboardEvent) => this["capture.keyup"].emit(event),
        keypress: (event: KeyboardEvent) => this["capture.keypress"].emit(event),
        mousedown: (event: MouseEvent) => this["capture.mousedown"].emit(event),
        mouseup: (event: MouseEvent) => this["capture.mouseup"].emit(event),
        mouseover: (event: MouseEvent) => this["capture.mouseover"].emit(event),
        mouseenter: (event: MouseEvent) => this["capture.mouseenter"].emit(event),
        mouseleave: (event: MouseEvent) => this["capture.mouseleave"].emit(event),
        mouseout: (event: MouseEvent) => this["capture.mouseout"].emit(event),
        scroll: (event: MouseEvent) => this["capture.scroll"].emit(event),
        contextmenu: (event: MouseEvent) => this["capture.contextmenu"].emit(event),
        focus: (event: FocusEvent) => this["capture.focus"].emit(event),
        blur: (event: FocusEvent) => this["capture.blur"].emit(event),
    };

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit() {
        for (let [event, handler] of Object.entries(this.listeners))
            this.elementRef.nativeElement.addEventListener(event, handler, true);
    }

    ngOnDestroy() {
        for (let [event, handler] of Object.entries(this.listeners))
            this.elementRef.nativeElement.removeEventListener(event, handler, true);
    }
}
