//@author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ItemI } from "../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-bulk-edit-issues-list")
@Component({
    selector: "s25-ng-bulk-edit-issues-list",
    template: ` <div class="cn-alert cn-alert--danger c-displayBlock">
        <div>{{ title }}</div>
        <table class="rose-object-occurrences-table bulk-edit-table">
            <thead>
                <tr>
                    @if (reference) {
                        <th>Reference</th>
                    }
                    @if (eventName) {
                        <th>Name</th>
                    }
                    @if (objectName) {
                        <th>Name</th>
                    }
                    @if (itemName) {
                        <th>Name</th>
                    }
                    @if (itemId) {
                        <th>Id</th>
                    }
                    @if (message) {
                        <th>Message</th>
                    }
                    @if (hasRoomsBool) {
                        <th>Rooms</th>
                    }
                </tr>
            </thead>
            <tbody>
                @for (e of items; track e) {
                    <tr>
                        @if (reference) {
                            <td>{{ e.event_locator }}</td>
                        }
                        @if (eventName) {
                            <td>{{ e.event_name }}</td>
                        }
                        @if (objectName) {
                            <td>{{ e.objectName }}</td>
                        }
                        @if (itemName) {
                            <td>{{ e.itemName }}</td>
                        }
                        @if (itemId) {
                            <td>{{ e.itemId }}</td>
                        }
                        @if (message) {
                            <td>{{ e.message }}</td>
                        }
                        <!--<td *ngIf="hasRoomsBool">
                  <span *ngFor="let r of e.rooms | limitTo: 10 track by $index">
                    {{(r.room_short + ($last ? "" : ", "))}}
                  </span>
                  <span *ngIf="e.rooms.length > 10">...</span>
                </td>-->
                    </tr>
                }
            </tbody>
        </table>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditIssuesListComponent implements OnInit {
    @Input() items: S25ItemI[];
    // @Input() itemTypeId: number;
    @Input() title: string;
    @Input() hasRooms?: any;

    reference: string;
    eventName: string;
    itemName: string;
    itemId: string;
    objectName: string;
    message: string;
    hasRoomsBool: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    ngOnInit(): void {
        this.hasRoomsBool = S25Util.toBool(this.hasRooms);
        this.eventName = this.items.length && this.items[0].event_name;
        this.objectName = this.items.length && this.items[0].objectName;
        this.itemId = this.items.length && (this.items[0].itemId as string);
        this.itemName = this.items.length && this.items[0].itemName;
        this.reference = this.items.length && this.items[0].event_locator;
        this.message = this.items.length && this.items[0].message;

        this.cd.detectChanges();
    }

    reset() {
        // this.message = "";
        // this.updating = false;
        this.cd.detectChanges();
    }
}
