import { NgModule } from "@angular/core";
import { S25CaptureEvent } from "./s25.capture.event.directive";

@NgModule({
    declarations: [S25CaptureEvent],
    exports: [S25CaptureEvent],
    providers: [S25CaptureEvent],
    imports: [],
})
export class S25CaptureEventModule {}
